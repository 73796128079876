.single__member {
  border: 1px solid #7c8a972d;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.single__member-social {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  position: absolute;
  top: -100%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: rgb(0, 13, 107, 0.6);
  transform: translateX(-50%);
  border-radius: 4px;
  opacity: 0;
  /* Removed the transition property */
}

.single__member-social a i {
  width: 28px;
  height: 28px;
  color: #000d6b;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  font-size: 0.9rem;
  font-weight: 600;
}

.single__member-social a {
  text-decoration: none;
  cursor: pointer;
}

.single__member-img {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Add this line to set a fixed height */
  height: 400px; /* Adjust the height as needed */
  overflow: hidden;
}

.single__member-img img {
  border-radius: 4px;
  /* Add these lines to maintain aspect ratio */
  height: 100%;
  width: auto;
}

.single__member-img:hover .single__member-social {
  top: 0;
  opacity: 1;
}

/* Default styles for larger screens */
.member-card {
  flex: 0 1 30%;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .member-card {
    flex: 0 1 45%; /* Two columns on medium devices */
  }
}

@media (max-width: 576px) {
  .member-card {
    flex: 0 1 100%; /* One column on small devices */
  }
}
