/* ======== google fonts ======== */
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* ======= base style ====== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: poppins;
}

/* ========= pre-define css ========= */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #831E1E;
  font-weight: 600;
  margin-bottom: 0;
}

h3,
h4 {
  font-size: 1.6rem;
}

h5,
h6 {
  font-size: 1.2rem;
}

a {
  text-decoration: none;
  color: unset;
}

.pre-wrap {
  white-space: pre-wrap;
}

ul {
  margin: 0;
  list-style: none;
}

.section__title {
  color: #831E1E;
  font-weight: 600;
  font-size: 3rem;
}

.section__subtitle {
  color: #000000;
  
  font-size: 2rem;
}

.section__description {
  color: #000000;
  font-size: 0.9rem;
  line-height: 30px;
}

section {
  padding: 50px 0px;
}

.btn {
  padding: 30px 20px;
  background: #6b0000;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  transition: 0.3s;
  width: 150px;
  height: 48px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn:hover {
  color: #efebeb !important;

}

@media only screen and (max-width: 768px) {
  section {
    padding: 30px 0px;
  }

  .section__title {
    font-size: 1.6rem;
  }

  .section__subtitle {
    font-size: 1.1rem;
  }
}


/* vvv */
/* Base styles */


/* Landing Page Background */

.landing-page-bg {
  background-image: url('../../React-Car-Rental-Website-master/src/assets/images/a-12.webp');
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 30px 0;
  
}

/* Section Left (Red Background) */

.section-left {
  padding: 20px;
  /* Adjusted margin for larger width */
  flex: 1; 
  width: 100%; /* Ensure section-left takes up remaining space */
}

.section-title {
  color: #fff;
  font-size: 2rem;
  margin-bottom: 20px;
}

.section-titlee{
  color: #fff;
  font-size: 2rem;
  margin-bottom: 20px;
}

.red-bg {
  /* background-color: #daadad; */
 
  padding-top: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: row; /* Displaying the divs in a column */
  gap: 20px; /* Gap between the red-divs */
  justify-content: center;
  justify-items: center;
}

.red-div {
  background-color: #831E1E;
  padding: 20px 20px 20px 20px;
  text-align: center; /* Center align text horizontally */
  margin-bottom: 10px;
  color: white;
  border-radius: 10px; /* Rounded corners for the red-divs */
  width: 100%; /* Full width by default */
  max-width: 400px; /* Max width for larger screens */
}

/* Section Right (Booking Form) */

.section-right {
  padding: 10px;
  flex: 1; 
  width: 100%; /* Set width to 100% to fill available space */
  display: flex;
  justify-content: center; /* Center the form horizontally */
}

.booking-info {
  /* border: 1px solid #ccc; */
  background: #fff;
  border-radius: 10px;
  padding: 15px; 
  width: 90%; /* Decreased padding for a more compact form */
}

/* Booking Form Adjustments */
.booking__form {
  width: 50%; /* Increase the width on larger devices */
}

.booking__form input {
  padding: 8px 15px;
  border: 1px solid #7c8a972a;
  width: 100%;
  color: rgb(110, 109, 109);
  background-color: transparent; /* Make input background transparent */
}

.booking__form select,
.textarea {
  width: 100%;
  padding: 10px 15px;
  color: rgb(110, 109, 109);
  border: 1px solid #7c8a972a;
  border-radius: 4px;
  background-color: transparent; /* Make select and textarea background transparent */
}

.time__picker::before {
  content: "Journey Time";
  margin-right: 0.5rem;
}

.textarea:focus,
.booking__form input:focus,
.booking__form select:focus {
  outline: none;
}

/* Date Input Styles */
.date {
  width: 100%; /* Set width to 100% */
  min-width: 250px; /* Set a minimum width */
  border-radius: 4px;
  padding: 4px 0px;
  color: rgb(110, 109, 109);
}

/* Media Queries */

@media only screen and (max-width: 992px) {
  .landing-page-bg {
    padding: 30px 0;
  }

  .section-left {
    margin-right: 0; /* No margin on smaller screens */
  }

  .red-div {
    max-width: 100%; /* Adjusted max-width for responsiveness */
    padding: 10px; 
    font-size: 14px;
   
  }

  .section-left,
  .section-right {
    margin-bottom: 20px;
    flex-direction: row;
  }

  .booking__form {
    width: 80%; /* Increase width on medium devices */
  }
  .section-titlee{
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .booking__form {
    width: 90%; /* Increase width on small devices */
  }

  .booking__form input,
  .textarea,
  .booking__form select {
    font-size: 0.8rem;
  }
  .red-bg{
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .booking__form {
    width: 100%; /* Increase width on extra small devices */
  }

  .booking__form input,
  .textarea,
  .booking__form select {
    font-size: 0.8rem;
  }
  
}

/* Media query specifically for iPhone */
@media only screen 
and (device-width: 375px) 
and (device-height: 812px) 
and (-webkit-device-pixel-ratio: 3) {
  .date {
    width: calc(100% - 30px); /* Adjust the width based on your design */
  }
}