.slider__item {
  max-width: 100%;
  height: 600px;
  position: relative; /* Ensure proper positioning of the pseudo-element */
}

.slider__item::after {
  content: ''; /* Create the pseudo-element */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
}

.slider__item-01 {
  background: url("../assets/images/a-12.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider__item-02 {
  background: url("../assets/backgrounds/business-setup-consultants.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider__item-03 {
  background: url("../assets/images/a-6.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero__slider {
  overflow: hidden;
}

.slider__content {
  position: relative; /* Ensure proper stacking context */
  z-index: 1; /* Place content above the pseudo-element */
  padding-top: 150px;
}

.slider__content h1 {
  font-size: 3.2rem;
  font-weight: 600;
}

.reserve__btn {
  background: #831E1E !important;
  color: #ffffff !important;
  border: none;
  outline: none;
  padding: 20px;
}


 .reserve__btn a {
  text-decoration: none;
  font-weight: 600;
  color: #ffffff;
}
.reserve__btn a :hover{
  color: #000000 !important;
}

.reserve__btn:hover {
  background: #000000;
  color: #fff;
}

.hero_btn{
  padding: 30px 20px;
  background: #6b0000;
  color: #a5a5a5;
  border: none;
  outline: none;
  border-radius: 10px;
  transition: 0.3s;
  width: 150px;
  height: 48px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

}

