.footer {
  background: #831E1E;
  padding-top: 50px;
  padding-bottom: 15px;
}

.f-icons {
  color: white;
  display: flex;
  gap: 30px; /* Adds space between the icons */
}

.footer-logo {
  height: 200px;
}

.footer__logo img {
  height: 60px;
}

.footer__logo span {
  color: #fff;
  font-weight: 500;
}

.footer__logo-content {
  color: rgba(255, 255, 255, 0.715);
  margin-top: 15px;
  line-height: 30px;
  max-width: 660px; 
  /* Increase the width as needed */
}

.quick__link {
  border: none !important;
  background: transparent !important;
}

.quick__link a {
  color: rgba(255, 255, 255, 0.715);
  text-decoration: none !important;
  -webkit-text-decoration: none !important;
  -moz-text-decoration: none !important;
  -ms-text-decoration: none !important;
  -o-text-decoration: none !important;
  font-weight: 300;
  transition: 0.3s;
}

.quick__link a:hover {
  color: #D48383;
}

.footer_link-title {
  color: #fff;
  size: 9rem;
}

.flinks {
  color: rgba(255, 255, 255, 0.715);
  font-weight: 300;
  text-decoration: none !important;
  -webkit-text-decoration: none !important;
  -moz-text-decoration: none !important;
  -ms-text-decoration: none !important;
  -o-text-decoration: none !important;
}

.flinks a {
  text-decoration: none !important;
  -webkit-text-decoration: none !important;
  -moz-text-decoration: none !important;
  -ms-text-decoration: none !important;
  -o-text-decoration: none !important;
  color: rgba(255, 255, 255, 0.715);
}

.flinks a:hover {
  text-decoration: none !important;
  -webkit-text-decoration: none !important;
  -moz-text-decoration: none !important;
  -ms-text-decoration: none !important;
  -o-text-decoration: none !important;
  color: rgba(255, 255, 255, 0.715);
}

.copyright {
  color: rgba(255, 255, 255, 0.715);
}

.newsletter input {
  background: transparent;
  color: #fff;
  border: none;
  outline: none;
}

.newsletter span i {
  color: #fff;
}

.footer__bottom {
  border-top: 1px solid #7c8a972f;
}

.social {
  padding-left: 80px;
}

@media only screen and (max-width: 768px) {
  .footer__logo-content {
    font-size: 0.8rem;
    max-width: 100%;
  }

  .quick__link,
  .office__info {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }

  .footer_link {
    text-decoration: none !important;
    -webkit-text-decoration: none !important;
    -moz-text-decoration: none !important;
    -ms-text-decoration: none !important;
    -o-text-decoration: none !important;
  }

  .footer_link-title {
    font-size: 1rem;
    margin-top: 15px;
    text-decoration: none !important;
    -webkit-text-decoration: none !important;
    -moz-text-decoration: none !important;
    -ms-text-decoration: none !important;
    -o-text-decoration: none !important;
  }

  .footer__bottom p {
    font-size: 0.7rem;
  }

  .social {
    padding-left: 80px;
  }
}

.footer-logo {
  width: auto;
  height: 40px;
}