/* Keyframes for the closing animation sliding to the right */
@keyframes slideOutRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

/* Keyframes for the opening animation sliding from the right */
@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

/* General Styles */
.active {
  font-weight: 500;
  color: #ff0000; /* Change this to your desired active link color */
}
.links{
  color: #000;
  text-decoration: none;
}
.links:hover{
  color: #831e1e !important;
}
.header__top {
  padding: 10px 0;
  background: #831e1e;
  color: #fff;
  height: 60px;
}

.logo-header {
  width: auto;
  height: 40px;
}

.header__button {
  background: #831e1e;
}

.header__top__left {
  display: flex;
  align-items: center;
  justify-content: start;
  column-gap: 1rem;
}

.header__top__left span {
  font-size: 0.9rem;
}

.header__top__help {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
}

.header__top__help i {
  background: #fff;
  color: #831e1e;
  padding: 2px 4px;
  border-radius: 50px;
  font-size: 0.7rem;
}

.header__top__right a {
  text-decoration: none;
  color: #fff;
  font-size: 0.9rem;
}

.header__top__right a:hover {
  color: #fff;
}

/* ============ header middle style =========== */
.header__middle {
  padding: 20px 0;
}

.logo h1 a span {
  font-size: 1.5rem;
}

.logo h1 a i {
  font-size: 2.2rem;
}

.logo h1 a {
  text-decoration: none;
  color: #831e1e;
  font-weight: 700;
}

.logo h1 {
  line-height: 1.5rem;
}

.header__location span i {
  font-size: 2.2rem;
  color: #831e1e;
}

.header__location-content h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #831e1e;
}

.header__location-content h6 {
  font-weight: 600;
}

.header__btn {
  background: #831e1e !important;
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 5px;
}

.header__btn a {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  text-decoration: none;
  color: #fff;
  justify-content: end;
  font-size: 0.9rem;
}

.header__btn a:hover {
  color: #fff;
}

/* Navbar Styles */
.a_navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-black);
  padding: 1.5rem 3rem;
  height: 80px;
}

.a_navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.a_navbar-logo h1 {
  width: 3rem;
  color: rgb(0, 0, 0);
  letter-spacing: 5px;
  text-transform: uppercase;
}

.a_navbar-links {
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: center;
  list-style: none;
  color: #000;
}

.a_navbar-links li {
  font-size: 22px;
  padding-top: 26px;
  margin: 0 1rem;
  cursor: pointer;
  font-size: 1rem;
  color: black; /* Change color to black */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s ease; /* Add transition effect for color change */
}

.a_navbar-links li:hover {
  color: rgb(177, 136, 144) !important;
}
.a_navbar-login {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 2.5rem;
}

.a_navbar-login a {
  margin: 0 1rem;
  text-decoration: none;
  transition: 0.5s ease;
}

.a_navbar-login a:hover {
  border-bottom: 1px solid var(--color-golden);
}

.border1 {
  width: 1px;
  height: 30px;
  background-color: var(--color-grey);
}

.a_navbar-smallscreen {
  display: none;
}

/* Mobile Menu Overlay */
.a_navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: white; /* Change background color to white */
  transition: transform 0.5s ease; /* Smooth transition */
  display: flex; /* Add Flexbox */
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  z-index: 5;
  transform: translateX(100%); /* Start off-screen */
}

.a_navbar-smallscreen_overlay.open {
  animation: slideInRight 0.5s forwards; /* Slide into view */
}

.a_navbar-smallscreen_overlay.close {
  animation: slideOutRight 0.5s forwards; /* Slide out of view */
}

.a_navbar-smallscreen_overlay .overlay__close {
  
  color: #000000; /* Adjust color if needed */
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.a_navbar-smallscreen-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
}

.a_navbar-smallscreen-links li {
  margin: 2rem 0; /* Adjust vertical spacing */
  cursor: pointer;
  color: black; /* Ensure text color is visible on white background */
  font-size: 20px;
  text-align: center;
}

.a_navbar-smallscreen-links li:hover {
  color: rgb(0, 0, 0); /* Change color on hover */
}

@media screen and (max-width: 1150px) {
  .a_navbar-links {
    display: none;
  }

  .a_navbar-smallscreen {
    display: flex;
  }

  .a_navbar-logo h1 {
    width: auto;
    color: rgb(0, 0, 0);
    letter-spacing: 5px;
    text-transform: uppercase;
  }
  
}

@media screen and (max-width: 650px) {
  .a_navbar {
    padding: 1rem;
  }

  .a_navbar-login {
    display: none;
  }

  .a_navbar-logo h1 {
    width: 110px;
  }
  

  .header__top {
    padding: 10px 0;
    background: #831e1e;
    color: #fff;
    height: 60px;
  }
  
  
}

@media only screen and (max-width: 768px) {
  .header__top {
    display: none;
  }

.row-nav{
  display: flex;
  justify-content: center;
}




}

@media screen and (max-width: 1150px) {
  .a_navbar {
    position: relative; /* Ensure the navbar is a positioned parent */
  }

  .logo-header {
    position: absolute;
    top: 20px; /* Adjust top positioning as needed */
    left: 22px; 
    /* Adjust left positioning as needed */
  }
  .cross{
    padding-top: 6px;
    height: 35px;
    width: 40px;
    color: #000;
  }
}


