.booking__form {
  width: 48%;
}

.booking__form input {
  padding: 8px 15px;
  border: 1px solid #7c8a972a;
  width: 100%;
  color: rgb(110, 109, 109);
  background-color: transparent; /* Make input background transparent */
}

.booking__form select,
.textarea {
  width: 100%;
  padding: 10px 15px;
  color: rgb(110, 109, 109);
  border: 1px solid #7c8a972a;
  border-radius: 4px;
  background-color: transparent; /* Make select and textarea background transparent */
}

.time__picker::before {
  content: "Journey Time";
  margin-right: 0.5rem;
}

.textarea:focus,
.booking__form input:focus,
.booking__form select:focus {
  outline: none;
}

/*  */

.date {
  width: 100%; /* Set width to 100% */
  min-width: 250px; /* Set a minimum width */
  border-radius: 4px;
  padding: 4px 0px;
  color: rgb(110, 109, 109);
}


@media only screen and (max-width: 768px) {
  .booking__form {
    width: 95%;
  }

  .booking__form input,
  .textarea,
  .booking__form select {
    font-size: 0.8rem;
  }
}

/* Media query specifically for iPhone */
@media only screen 
and (device-width: 375px) 
and (device-height: 812px) 
and (-webkit-device-pixel-ratio: 3) {
  .date {
    width: calc(100% - 30px); /* Adjust the width based on your design */
  }
}
