.service__item {
  background-color: #ffdede;
  border-radius: 30px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.service__item img {
  max-width: 50px; /* Adjust the maximum width of the image */
  max-height: 50px; /* Adjust the maximum height of the image */
  display: block; /* Ensures proper alignment */
  margin: 0 auto 10px; /* Centers the image horizontally and adds spacing below */
}

.service__item span i {
  font-size: 1.2rem; /* Adjust the font size as needed */
  color: #f9a826;
  margin-bottom: 10px; /* Adjust spacing between icon and heading */
}

.service__item h6 {
  font-size: 1.2rem;
  color: black;
  font-weight: 600;
  transition: 0.3s;
}

.service__item h6:hover {
  color: #f9a826;
}

@media only screen and (max-width: 768px) {
  .service__item h6 {
    font-size: 1.1rem;
  }
}
