.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .popup {
    background-color: #ffdede;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 700px;
    text-align: center;
    z-index: 1100; /* Higher z-index than overlay to ensure it's on top */
    position: relative; /* Ensure close button is positioned relative to the popup */
  }
  
  .popup-logo {
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
  }
  
  .popup-offer {
    margin-bottom: 10px;
  }
  
  .offer-line {
    font-weight: bold;
    color: #831E1E; /* Red color */
    font-size: 30px; /* Larger font size */
  }
  
  .headingb {
    font-weight: 600;
    margin-bottom: 0;
    font-size: 24px;
  }
  
  .redb {
    color: #831E1E;
  }
  
  .popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .popup-text {
    margin-bottom: 20px;
    background-color: white;
    text-align: center; /* Adjust text alignment */
    font-weight: 700;
    font-size: 20px;
    padding: 6px;
    border-radius: 20px;
  }
  
  .popup-text p {
    margin-bottom: 5px; /* Reduced margin for paragraphs */
  }
  
  .popup-image img {
    max-width: 100%; /* Ensure image does not exceed container width */
    height: auto; /* Maintain aspect ratio */
  }
  
  .popup-actions {
    margin-top: 20px; /* Add space between content and button */
  }
  
  .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #333;
  }
  
  /* Media Query for Small Devices */
  @media (max-width: 576px) {
    .offer-line {
      font-size: 24px; /* Adjusted font size for smaller screens */
    }
  
    .headingb {
      font-size: 20px; /* Adjusted font size for smaller screens */
    }
  
    .popup-text {
      font-size: 16px; /* Adjusted font size for smaller screens */
      padding: 5px; /* Reduced padding */
    }
  }
  