.become__driver {
  background: linear-gradient(rgba(107, 18, 0, 0.6), rgba(164, 21, 11, 0.6)),
    url("../assets/images/a-20.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* padding: 40px 0px; */
  height: 600px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.become__driver button {
  background: #810f0f;
  color: #ffffff;
  font-weight: 600;
}

.become__driver-title {
  font-size: 2.8rem;
  color: #fff;
  line-height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.becomedriver{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 992px) {
  .become__driver-title {
    font-size: 1.5rem;
    line-height: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .become__driver {
  height: 200px;
  }

  .become__driver-title {
    font-size: 1.3rem;
    line-height: 2.5rem;
  }

  .become__driver-btn {
    font-size: 0.8rem !important;
  }

}

@media only screen and (max-width: 576px) {
  .become__driver-img {
    display: none;
  }
  .become__driver {
    text-align: center;
  }
}
